import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="navOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper1">
                <div id="block-menu-primary-links" className="block block-menu">
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first home">
                        <a href="/home" title="Home">
                          <img src="/sites/migraleve.co.uk/files/images/home.png" />
                        </a>
                      </li>
                      <li className="expanded aboutmigraines">
                        <a href="/about-migraine" title="About Migraines" className="about-migraine">
                          ABOUT MIGRAINES
                        </a>
                        <ul className="menu">
                          <li className="leaf first migrainesymptoms">
                            <a href="/migraine-symptoms" title="Migraine Types & Symptoms">
                              Migraine Types & Symptoms
                            </a>
                          </li>
                          <li className="leaf potentialmigrainecauses">
                            <a
                              href="/migraine-causes"
                              title="Potential Migraine Causes"
                            >
                              Potential Migraine Causes
                            </a>
                          </li>
                          <li className="leaf last whatcanyoudoaboutit">
                            <a
                              href="/migraine-treatment-and-prevention"
                              title="Treatment and Relief"
                            >
                              Treatment and Relief
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="leaf ourproducts">
                        <a
                          href="/migraine-relief-products"
                          title="Our Products"
                        >
                          OUR
                          <br /> PRODUCTS
                        </a>
                      </li>
                      <li className="leaf migrainemanagementtips">
                        <a
                          href="/migraine-tips"
                          title="Migraine Management Tips"
                        >
                          MIGRAINE MANAGEMENT TIPS
                        </a>
                      </li>
                      <li className="leaf migrainetriggerdiary">
                        <a
                          href="/migraine-trigger-diary"
                          title="Migraine Trigger Diary"
                        >
                          MIGRAINE TRIGGER DIARY
                        </a>
                      </li>
                      <li className="leaf last wheretobuy">
                        <a href="/where-to-buy" title="Where to buy">
                          WHERE TO BUY
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contentOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div className="homeBannerWrapper">
                  <div className="innerPageContentWrapper">
                    <div className="innerPageContent privacy-n-Legal">
                      <div id="node-3" className="node">
                        <div className="content clear-block">
                          <h1>Legal Notice</h1>
                          <p>
                            All content of this Internet site is owned or
                            controlled by Johnson &amp; Johnson Limited and is
                            protected by worldwide copyright laws. You may
                            download content only for your personal use for
                            non-commercial purposes but no modification or
                            further reproduction of the content is permitted.
                            The content may otherwise not be copied or used in
                            anyway.
                          </p>
                          <p>
                            The owners of this site will use reasonable efforts
                            to include up-to-date and accurate information in
                            this Internet site, but make no representations,
                            warranties, or assurances as to the accuracy,
                            currency, or completeness of the information
                            provided. The owners of this site shall not be
                            liable for any damages or injury resulting from your
                            access to, or inability to access, this Internet
                            site, or from your reliance on any information
                            provided at this Internet site. This site is for
                            residents of the United Kingdom.
                          </p>
                          <p>
                            This Internet site may provide links or references
                            to other sites but the owners of this site have no
                            responsibility for the content of such other sites
                            and shall not be liable for any damages or injury
                            arising from that content. Any links to other sites
                            are provided as merely a convenience to the users of
                            this Internet site.
                          </p>
                          <p>
                            The trademarks, service marks, trade names, trade
                            dress and products in this Internet site are
                            protected in the United Kingdom and internationally.
                            No use of any of these may be made without the
                            prior, written authorization of the owners of this
                            site, except to identify the products or services of
                            the company.
                          </p>
                          <p>
                            Any personally identifiable information in
                            electronic communications to this Internet site is
                            governed by this site's Privacy Policy. The owners
                            of this site shall be free to use or copy all other
                            information in any such communications, including
                            any ideas, inventions, concepts, techniques or
                            know-how disclosed therein, for any purposes. Such
                            purposes may include disclosure to third parties
                            and/or developing, manufacturing and/or marketing
                            goods or services.
                          </p>
                          <p>
                            The sender of any communications to this Internet
                            site or otherwise to the owners of this site shall
                            be responsible for the content and information
                            contained therein, including its truthfulness and
                            accuracy.
                          </p>
                          <address>
                            <p>
                              Johnson &amp; Johnson Limited <br />
                              50 - 100 Holmers Farm Way,<br />
                              High Wycombe, HP12 4EG<br />
                              United Kingdom<br />
                            </p>
                          </address>
                          <p>Company registration number: 02930463</p>
                          <p>
                            <a
                              href="https://www.jnj.com/about-jnj/company-statements/tax-policy-statement"
                              target="_blank"
                            >
                              Tax Policy Statement
                            </a>
                          </p>
                        </div>
                        <div className="clear-block">
                          <div className="meta"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div className="footerContent">
                  <div
                    id="block-menu-menu-site-footer-menu"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="leaf first privacypolicy">
                          <a href="/privacy-policy" title="Privacy policy">
                            Privacy policy
                          </a>
                        </li>
                        <li className="leaf active-trail legalnotice">
                          <a
                            href="/legal-notice"
                            title="Legal notice"
                            className="active"
                          >
                            Legal notice
                          </a>
                        </li>
                        <li className="leaf contactus">
                          <a href="/contact-us" title="Contact us">
                            Contact us
                          </a>
                        </li>
                        <li className="leaf cookiepolicy">
                          <a href="/cookie-policy" title="Cookie Policy">
                            Cookie Policy
                          </a>
                        </li>
                        <li className="leaf modernslaveryactstatement">
                          <a
                            href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                            target="_blank"
                          >
                            Modern Slavery Act Statement
                          </a>
                        </li>
                        <li className="leaf last cookiesettings">
                          <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                            Cookie Settings
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div id="block-block-3" className="block block-block">
                    <div className="content">
                      <p>
                        <strong>
                          Always read the label. Ask your pharmacist for advice.
                        </strong>
                      </p>
                      <p>
                        MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                        codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                        codeine and buclizine. MIGRALEVE<em>™</em> Yellow
                        contains paracetamol and codeine.
                      </p>
                      <p>
                        Can cause addiction. For three days use only. This
                        medicine can make you feel sleepy. Do not drive while
                        taking this medicine until you know how it makes you
                        feel.
                      </p>
                      <p>
                        This site is published by Johnson &amp; Johnson Limited
                        which is solely responsible for its contents. It is
                        intended for a UK audience. MIGRALEVE<em>™</em> is a
                        registered trademark.
                      </p>
                      <p>
                        This site is best viewed with the following web
                        browsers: Internet Explorer version 8, Firefox version
                        2+, Safari 3+, Chrome version 3+.
                      </p>
                      <p>
                        © Johnson &amp; Johnson Limited 2024. This site was last
                        updated on: 15<sup>th </sup>August 2024
                      </p>
                    </div>
                  </div>
                  <p>
                    <a
                      id="popup-trigger"
                      href="/sites/migraleve.co.uk/files/pop-up-text"
                      rel="lightmodal"
                    >
                      hide this
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
